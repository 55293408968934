// this is a example model structure
// export class Earning {
//     constructor(obj) {
//       this.balance = obj.balance;
//       this.description = obj.description;
//       this.title = obj.title;
//       this.offIconUrl = obj.icon_url;
//       this.valueInSikka = obj.valueInSikka;
//       this.createdAt = obj.createdAt;
//       this.transaction_type = obj.transaction_type;
//     }
//     static from(obj) {
//       if (!obj) return null;
//       const earning = new Earning(obj);

import { Payout } from './offer';

//       earning.valueInSikka = obj.value.amount;
//       earning.createdAt = obj.created_at_unix * 1000;
//       return earning;
//     }
//     static fromAll(data) {
//       if (!data) return null;
//       return data.map(Earning.from);
//     }
//   }

//this is the daily checking structure
// {
//     "streaks": [
//         {
//             "day": 1,
//             "payout": {
//                 "amount": 0.25,
//                 "currency": "sikka"
//             },
//             "status": "AVAILABLE"
//         },
//         {
//             "day": 2,
//             "payout": {
//                 "amount": 0.5,
//                 "currency": "sikka"
//             },
//             "status": "PENDING"
//         },
//         {
//             "day": 3,
//             "payout": {
//                 "amount": 1,
//                 "currency": "sikka"
//             },
//             "status": "PENDING"
//         },
//         {
//             "day": 4,
//             "payout": {
//                 "amount": 1.25,
//                 "currency": "sikka"
//             },
//             "status": "PENDING"
//         },
//         {
//             "day": 5,
//             "payout": {
//                 "amount": 1.5,
//                 "currency": "sikka"
//             },
//             "status": "PENDING"
//         },
//         {
//             "day": 6,
//             "payout": {
//                 "amount": 2.5,
//                 "currency": "sikka"
//             },
//             "status": "PENDING"
//         }
//     ],
//     "current_streak": 0,
//     "next_reward_day": 1,
//     "next_reward_unlock_at": 1705084200,
//     "next_reward_time_left": 0,
//     "error_code": 0,
//     "error_value": ""
// }
//create a model for daily checking
export class DailyCheckin {
  constructor(obj) {
    /**
     * @type {Streak}
     */
    this.streaks = obj.streaks;
    this.currentStreak = obj.currentStreak;
    this.nextRewardDay = obj.nextRewardDay;
    this.nextRewardUnlockAt = obj.nextRewardUnlockAt;
    this.nextRewardTimeLeft = obj.nextRewardTimeLeft;
    this.errorCode = obj.errorCode;
    this.errorValue = obj.errorValue;
  }

  canClaimToday() {
    return this.nextRewardTimeLeft === 0;
  }

  completeOfferToUnlock() {
    return this.errorCode === 2001;
  }

  /**
   *
   * @returns {Streak}
   */
  getTodaysStreak() {
    return this.streaks.find(d => d.isAvailable());
  }

  getTodaysClaimed() {
    const claimedStreak = this.streaks.filter(d => d.isClaimed());
    return claimedStreak[claimedStreak.length - 1];
  }

  static from(obj) {
    if (!obj) return null;
    return new DailyCheckin({
      streaks: Streak.fromAll(obj.streaks),
      currentStreak: obj.current_streak,
      nextRewardDay: obj.next_reward_day,
      nextRewardUnlockAt: obj.next_reward_unlock_at,
      nextRewardTimeLeft: obj.next_reward_time_left,
      errorCode: obj.error_code,
      errorValue: obj.error_value,
    });
  }
}

export class Streak {
  constructor(obj) {
    this.day = obj.day;
    /**
     * @type {Payout}
     */
    this.payout = obj.payout;
    this.status = obj.status;
  }
  isClaimed() {
    return this.status === 'CLAIMED';
  }
  isAvailable() {
    return this.status === 'AVAILABLE';
  }
  isPending() {
    return this.status === 'PENDING';
  }
  static from(obj) {
    if (!obj) return null;
    return new Streak({
      day: obj.day,
      payout: Payout.from(obj.payout),
      status: obj.status,
    });
  }

  static fromAll(objs) {
    if (!objs) return null;
    return objs.map(Streak.from);
  }
}
