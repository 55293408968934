'use client';
import Button from '@/components/ui/Button';
import {
  CONTEST_PAGE,
  REFERRALS_PAGE,
  REFERRAL_LIST_PAGE,
} from '@/constants/routes.const';
import { cn } from '@/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export default function RouteLink({ title, href, MenuIcon }) {
  const pathName = usePathname();

  const isActive = useMemo(() => {
    if (pathName == href) {
      return true;
    }
    if (pathName === REFERRAL_LIST_PAGE && href === REFERRALS_PAGE) {
      return true;
    }
    if (href === CONTEST_PAGE && pathName.includes(CONTEST_PAGE)) {
      return true;
    }
    return false;
  }, [pathName, href]);

  return (
    <Link href={href} className={'contents'}>
      <Button
        variant={'soft'}
        size={'2'}
        color={cn('gray')}
        highContrast={isActive}
        className={cn(
          'flex flex-col items-center gap-0 p-1 , h-[60px] rounded-none bg-white',
          !isActive && 'text-black/40',
          isActive && 'text-accent-color font-bold'
        )}
      >
        <>
          <MenuIcon className="text-[24px]" />
          <p className="text-[10px]">{title}</p>
        </>
      </Button>
    </Link>
  );
}
