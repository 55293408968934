import { apiAxiosV1 } from '@/configs/axios';
import { API_END_POINT_V2_2 } from '@/configs/config';
import { DailyCheckin } from '@/model/DailyCheckin';

export const DailyCheckinService = {
  async getDailyCheckinStatus() {
    const res = await apiAxiosV1.get(
      API_END_POINT_V2_2 + '/dailycheckin/status'
    );
    return DailyCheckin.from(res?.data);
  },
  async claimDailyCheckin() {
    const res = await apiAxiosV1.get('/dailycheckin/claim');
    return res?.data;
  },
};
