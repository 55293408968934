import React from 'react';
// import * as AvatarCo/re from '@radix-ui/react-avatar';
import { User } from '@/model/Users';
import { Avatar } from '@radix-ui/themes';
/**
 *
 * @param {{user: User}} param0
 * @returns
 */
export default function Avatars({ user, size = 'md', style = {} }) {
  if (!user) return null;
  const name = user?.name || user?.fullName;
  return (
    <Avatar
      fallback={getTwoInitials(name)}
      src={user?.avatar?.imgUrl}
      radius="full"
      size={sizeToNum(size)}
      style={style}
      color={getColor(name)}
      variant="solid"
    />
  );
}

function getTwoInitials(name = '') {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .slice(0, 2);
}

function sizeToNum(size) {
  if (size === 'sm') return '1';
  if (size === 'md') return '3';
  if (size === 'lg') return '5';
  return '2';
}

function assignColorsToAlphabatesAndNumbers() {
  const colors = [
    'ruby',
    'tomato',
    'red',
    'crimson',
    'pink',
    'plum',
    'purple',
    'violet',
    'iris',
    'indigo',
    'blue',
    'cyan',
    'teal',
    'jade',
    'green',
    'grass',
    'brown',
    'orange',
    'sky',
    'gray',
  ];
  const alphabets = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const colorMap = {};
  for (let i = 0; i < alphabets.length; i++) {
    colorMap[alphabets[i]] = colors[i];
  }
  for (let i = 0; i < numbers.length; i++) {
    colorMap[numbers[i]] = colors[i];
  }
  return colorMap;
}

function getColor(text = 'a') {
  const char = text[0].toLowerCase();
  const colorMap = assignColorsToAlphabatesAndNumbers();
  return colorMap[char];
}
